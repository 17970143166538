function Footer() {
	this.selectors = Array();

	this.init = () => {
		if (!document.querySelector(".site-footer")) {
			return;
		}
		this.selectors = {
			footer: document.querySelector(".site-footer"),
			footerMagenta: document.querySelector(".footer-magenta"),
			footerFixed: document.querySelector(".footer-fixed"),
			content: document.querySelector(".site-content"),
		};
		this.observeFooter();
		this.observeFooterFixed();
	};

	this.observeFooter = () => {
		const footerObserver = new IntersectionObserver(
			(entries, observer) => {
				entries.forEach((entry) => {
					// Stop JWPlayer floating video before user scrolls to the footer.
					this.selectors.content.style.transform = entry.isIntersecting
						? "translate(0)"
						: "";
				});
			},
			{ threshold: 0 }
		);

		footerObserver.observe(this.selectors.footer);
	};

	this.observeFooterFixed = () => {
		if (this.selectors.footerFixed) {
			const footerFixedObserver = new IntersectionObserver(
				(entries, observer) => {
					entries.forEach((entry) => {
						if (entry.boundingClientRect.top < window.innerHeight) {
							// Fixed footer visibility on scroll.
							this.selectors.footerFixed.style.display = entry.isIntersecting
								? "none"
								: "block";
						}
					});
				},
				{ threshold: 0, rootMargin: "-100% 0% 0% 0%" }
			);

			footerFixedObserver.observe(this.selectors.footerMagenta);
		}
	};
}

export default new Footer();
