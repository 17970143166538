function expandedMenu() {
	const toggleNav = function (el) {
		const overlay = document.querySelector(".overlay");
		const contentEl = document.querySelector(".site");

		if (el.classList.contains("open")) {
			el.classList.remove("open");
			overlay.style.display = "none";
			contentEl.style.overflow = "initial";
			contentEl.style.height = "auto";
		} else {
			el.classList.add("open");
			overlay.style.display = "block";
			contentEl.style.overflow = "hidden";
			contentEl.style.height = "0";
		}

		overlay.addEventListener("click", (e) => {
			toggleNav(el);
		});
	};

	$(window).on("injector:expandedmenu-load", () => {
		const hamburgerMenu = document.querySelector(".hamburger-menu");
		const searchEl = document.querySelector(".site-branding .search");
		const closeEl = document.querySelector(".expanded-menu .close");

		if (hamburgerMenu) {
			hamburgerMenu.addEventListener("click", (e) => {
				e.preventDefault();
				const expandedMenu = document.querySelector(".expanded-menu");
				if (expandedMenu) {
					toggleNav(expandedMenu);
				}
			});
		}
		if (searchEl) {
			searchEl.addEventListener("click", (e) => {
				e.preventDefault();
				const expandedMenu = document.querySelector(".expanded-menu");
				if (expandedMenu) {
					toggleNav(expandedMenu);
				}
			});
		}
		if (closeEl) {
			closeEl.addEventListener("click", (e) => {
				e.preventDefault();
				const expandedMenu = document.querySelector(".expanded-menu");
				if (expandedMenu) {
					toggleNav(expandedMenu);
				}
			});
		}

		//sticky header
		const header = document.getElementById("masthead");
		const wpAdminMenu = document.getElementById("wpadminbar");
		const extraspace = wpAdminMenu ? wpAdminMenu.offsetHeight : 0;
		const sticky = header ? header.offsetHeight + extraspace : 0;

		window.addEventListener(
			"scroll",
			essThrottle(function () {
				if (window.pageYOffset > sticky /*&& unstick > window.pageYOffset*/) {
					if (header) {
						header.classList.add("fixed");
					}
					if (wpAdminMenu) {
						wpAdminMenu.classList.add("fixed");
					}
				} else {
					if (header) {
						header.classList.remove("fixed");
					}
					if (wpAdminMenu) {
						wpAdminMenu.classList.remove("fixed");
					}
				}
			})
		);

		// Expanded menu sub menu toggle.
		$(".expanded-menu .current-menu-parent")
			.addClass("expanded")
			.find(".sub-menu")
			.show();
		$(".expanded-menu .menu-item-has-children > a").on("click", function (e) {
			if (e.offsetX > e.target.offsetWidth - 40) {
				e.preventDefault();
				const parent = $(e.target).parent();

				if (
					$("#menu-expanded-menu li").hasClass("expanded") &&
					!parent.hasClass("expanded")
				) {
					$("#menu-expanded-menu li").find(".sub-menu").slideUp();
					$("#menu-expanded-menu li").removeClass("expanded");
				}
				parent.toggleClass("expanded");
				if (parent.hasClass("expanded")) {
					parent.find(".sub-menu").slideDown();
				} else {
					parent.find(".sub-menu").slideUp();
				}
			}
		});
	});
}

export default expandedMenu;
